<template>
  <section class="info-container">
    <section class="search-container">
      <a-row class="m-t-10">
        <span class="m-r-10">设备名称</span>
        <a-input class="m-r-10 search-input" placeholder="请输入" v-model="deviceName" />
        <span class="m-r-10">设备编号</span>
        <a-input class="m-r-10 search-input" placeholder="请输入" v-model="deviceNo" />
        <span class="m-r-10">状态</span>
        <a-select v-model="status" class="m-r-10 search-select" placeholder="请选择">
          <a-select-option value>全部</a-select-option>
          <a-select-option value="0">未接入</a-select-option>
          <a-select-option value="1">已接入</a-select-option>
        </a-select>
        <a-button type="primary" class="m-r-8" @click="search">
          <a-icon type="search" />查询
        </a-button>
        <a-button @click="reset">
          <a-icon type="reload" />重置
        </a-button>
        <!-- <router-link to="/spms/Kanban/plan">
          <a-button type="primary" class="f-right">投放计划</a-button>
        </router-link>
        <router-link to="/spms/Kanban/add">
          <a-button type="primary" class="f-right m-r-10">添加</a-button>
        </router-link>-->
      </a-row>
    </section>
    <section class="info-item-wrap m-t-18">
      <a-row class="m-t-18">
        <!-- <a-button class="f-right" type="primary">导出</a-button> -->
        <a-button
          @click="addVisible = true"
          class="f-right"
          style="margin-right:15px"
          type="primary"
        >添加设备</a-button>
      </a-row>
      <p></p>
      <section class="list-table-container">
        <a-table size="small" :columns="columns" :pagination="false" :data-source="listMap">
          <a slot="action" slot-scope="text">
            <!-- <a class="m-r-10" @click="showAudioModal(list)">试听</a> -->
            <a class="m-r-10" @click="check(text)">查看</a>
            <a class="m-r-10" @click="edit(text)">编辑</a>
            <a-popconfirm title="确定删除该设备?" @confirm="deleteItem(text.id)">删除</a-popconfirm>
          </a>
        </a-table>
        <section class="pagination-container">
          <a-pagination
            show-quick-jumper
            show-size-changer
            :current="data.current"
            :total="data.total"
            :show-total="(total) => `共${total}条`"
            @change="onCurrentChange"
            @showSizeChange="onShowSizeChange"
          />
        </section>
      </section>
    </section>

    <a-modal
      centered
      width="660px"
      class="modal-container"
      title="添加设备"
      v-model="addVisible"
      :footer="false"
      :destroyOnClose="true"
      @cancel="addVisible = false"
    >
      <a-form :form="form" layout="inline" class="m-t-18" style="text-align:center">
        <a-row style="margin-bottom:15px;">
          <a-form-item label="设备名称">
            <a-input
              placeholder="请输入"
              style="width:200px"
              class="form-input"
              v-decorator="[
              'deviceName',
              { rules: [{ required: true, message: '设备名称不能为空' }] },
            ]"
            ></a-input>
          </a-form-item>
        </a-row>
        <a-row style="margin-bottom:15px;">
          <a-form-item label="设备编号">
            <a-input
              placeholder="请输入"
              class="form-input"
              style="width:200px"
              v-decorator="[
              'deviceNo',
              { rules: [{ required: true, message: '设备编号不能为空' }],
              },
            ]"
            ></a-input>
          </a-form-item>
        </a-row>
        <a-row style="margin-bottom:15px;">
          <a-form-item label="所属区域">
            <a-select
              class="search-input"
              style="width:200px"
              v-decorator="[
              'areaId',
              { rules: [{ required: true, message: '所属区域不能为空' }] },
            ]"
            >
              <a-select-option
                v-for="(item,index) in areaList"
                :key="index"
                :value="item.id"
              >{{item.name}}</a-select-option>
            </a-select>
          </a-form-item>
        </a-row>
        <a-row class="form-btn-group" style="text-align:center">
          <a-button type="primary" @click="saveName">保存</a-button>
          <a-button class="btn-cancel" @click="addVisible = false">取消</a-button>
        </a-row>
      </a-form>
    </a-modal>

    <a-modal
      centered
      width="660px"
      class="modal-container"
      title="编辑设备"
      v-model="editVisible"
      :footer="false"
      :destroyOnClose="true"
      @cancel="editVisible = false"
    >
      <a-form :form="form" layout="inline" class="m-t-18" style="text-align:center">
        <a-row style="margin-bottom:15px;">
          <a-form-item label="设备名称">
            <a-input
              placeholder="请输入"
              style="width:200px"
              class="form-input"
              v-decorator="[
              'deviceName',
              { rules: [{ required: true, message: '设备名称不能为空' }],
              
              initialValue:item.deviceName },
            ]"
            ></a-input>
          </a-form-item>
        </a-row>
        <a-row style="margin-bottom:15px;">
          <a-form-item label="设备编号">
            <a-input
              placeholder="请输入"
              class="form-input"
              style="width:200px"
              v-decorator="[
              'deviceNo',
              { rules: [{ required: true, message: '设备编号不能为空' }],
              
              initialValue:item.deviceNo },
            ]"
            ></a-input>
          </a-form-item>
        </a-row>
        <a-row style="margin-bottom:15px;">
          <a-form-item label="所属区域">
            <a-select
              class="search-input"
              style="width:200px"
              v-decorator="[
              'areaId',
              { rules: [{ required: true, message: '所属区域不能为空' }],
              
              initialValue:item.areaId },
            ]"
            >
              <a-select-option
                v-for="(item,index) in areaList"
                :key="index"
                :value="item.id"
              >{{item.name}}</a-select-option>
            </a-select>
          </a-form-item>
        </a-row>
        <a-row class="form-btn-group" style="text-align:center">
          <a-button type="primary" @click="updateName">保存</a-button>
          <a-button class="btn-cancel" @click="editVisible = false">取消</a-button>
        </a-row>
      </a-form>
    </a-modal>
    <a-modal
      centered
      width="660px"
      class="modal-container"
      title="设备信息"
      v-model="showVisible"
      :footer="false"
      :destroyOnClose="true"
      @cancel="showVisible = false"
    >
      <a-row class="modalRow">
        <a-col :span="12">
          <span>设备名称</span>
        </a-col>
        <a-col :span="4">{{showItem.deviceName}}</a-col>
      </a-row>
      <a-row class="modalRow">
        <a-col :span="12">
          <span>设备编号</span>
        </a-col>
        <a-col :span="4">{{showItem.deviceNo}}</a-col>
      </a-row>
      <a-row class="modalRow">
        <a-col :span="12">
          <span>所属区域</span>
        </a-col>
        <a-col :span="4">{{showItem.areaName}}</a-col>
      </a-row>
      <a-row class="modalRow">
        <a-col :span="12">
          <span>设备状态</span>
        </a-col>
        <a-col :span="4">{{showItem._status}}</a-col>
      </a-row>
    </a-modal>
  </section>
</template>

<script>
const columns = [

  { title: '序号', dataIndex: 'ind', align: 'center', key: 'ind' },
  { title: '设备名称', dataIndex: 'deviceName', align: 'center', key: 'deviceName' },
  { title: '设备编号', dataIndex: 'deviceNo', align: 'center', key: 'deviceNo' },
  { title: '所属区域', dataIndex: 'areaName', align: 'center', key: 'areaName' },
  { title: '设备状态', dataIndex: '_status', align: 'center', key: 'status' },
  { title: '操作', key: 'logImage', scopedSlots: { customRender: "action" }, align: 'center' }
]
// import moment from 'moment'
export default {
  data() {
    return {
      columns,
      total: 0,
      listMap: [],
      playLists: [],
      //分页
      data: {
        current: 1,
        size: 10,
      },
      //模态框变量
      addVisible: false,
      showVisible: false,
      editVisible: false,
      showName: '',//用于展示时的名称
      item: {},//获取数据
      showItem: {},//查看详情时的数据
      //查询数据
      deviceName: '',//设备名称
      deviceNo: '',//设备编号
      status: '',//设备状态
      areaList: [],//区域列表
    }
  },
  components: {},
  beforeCreate() {
    this.form = this.$form.createForm(this)
  },
  created() {
    this.getBroadcastPage();
    this.getBaseConfig();
  },
  methods: {
    //查询基本配置列表
    getBroadcastPage() {
      let params = {
        current: this.data.current,
        size: this.data.size,
        deviceName: this.deviceName,
        deviceNo: this.deviceNo,
        status: this.status
      }
      if (params.deviceName == '') delete params.deviceName;
      if (params.deviceNo == '') delete params.deviceNo;
      if (params.status == '') delete params.status;
      this.$api.getBroadcastPage(params).then(res => {
        if (res.code === 200) {
          this.listMap = (res.data && res.data.records) || []
          this.listMap.forEach((item, ind) => {
            item.ind = ind + 1
            item._status = item.status ? '已接入' : '未接入'
          })
          this.data.total = (res.data && res.data.total) || 0
          this.data.current = (res.data && res.data.current) || 1

        }
      })
    },
    getBaseConfig() {
      this.$api.getBaseConfig({
        current: 1,
        size: 50
      }).then(res => {
        if (res.code == 200) {
          console.log('res', res.data);
          this.areaList = (res.data && res.data.records) || []
        }
      })
    },
    saveName() {
      this.form.validateFields(async (err, formData) => {
        if (!err) {
          let params = { ...formData };
          console.log('params', params);
          await this.$api.addBroadcast(params).then(res => {
            if (res.code == 200) {

              this.$message.success('添加成功');
              this.addVisible = false;
              this.data.current = 1;
              this.data.size = 10;
              this.getBroadcastPage();
            }
            else {
              this.$message.error(res.msg)
            }
          })
          //   this.$router.push("/accident/expressNews");
        }
      });
    },
    updateName() {
      this.form.validateFields(async (err, formData) => {
        if (!err) {
          let params = { ...formData };
          params.id = this.item.id;
          console.log('params', params);
          await this.$api.updateBroadcast(params).then(res => {
            if (res.code == 200) {

              this.$message.success('修改成功');
              this.editVisible = false;
              this.data.current = 1;
              this.data.size = 10;
              this.getBroadcastPage();
            }
            else {
              this.$message.error(res.msg)
            }
          })
          //   this.$router.push("/accident/expressNews");
        }
      });
    },
    check(item) {
      this.showVisible = true;
      this.showItem = item;
      console.log(this.showItem);
    },
    edit(obj) {
      this.item = obj;
      this.editVisible = true;
    },
    deleteItem(id) {

      let params = {
        id: id
      }
      this.$api.deleteBroadcast(params)
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.$message.success("删除成功");

            this.data.current = 1;
            this.data.size = 10;
            this.getBroadcastPage();
          }
          else {
            this.$message.error(res.msg)
          }
        })
        .catch(() => {
          //   this.$message.error(err);
        });
    },
    //分页相关

    onCurrentChange(page, pageSize) {
      this.$set(this.data, "current", page);
      this.$set(this.data, "size", pageSize);
      this.getBroadcastPage();
    },
    onShowSizeChange(current, size) {
      this.$set(this.data, "current", current);
      this.$set(this.data, "size", size);
      this.getBroadcastPage();
    },
    search() {

      this.data.current = 1;
      this.data.size = 10;
      this.getBroadcastPage();
    },
    reset() {
      this.data.current = 1;
      this.data.size = 10;
      this.deviceName = '';
      this.deviceNo = '';
      this.status = '';
      this.getBroadcastPage();
    }
  },
  beforeDestroy() {
  },
}
</script>
<style lang="scss" scoped>
// 页面分页
.pagination-container {
  position: absolute;
  bottom: 0;
  height: 60px;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  //修复分页样式错误
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    svg {
      height: 32px;
    }
  }
}
.modalRow {
  margin-bottom: 15px;
  text-align: center;
  span {
    color: #cfd2dd;
  }
}
</style>